<template>
  <b-card-code
    title=""
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- detail View handler -->
      <template #cell(show_details)="row">

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          {{ row.detailsShowing ? 'Hide' : 'Show' }}
        </b-form-checkbox>
      </template>

      <!-- full detail on click -->
      <template #row-details="row">

        <!-- table detail view -->
        <company-table-detail-view
          :item="row.item"
        />

      </template>

      <!-- Name -->
      <template #cell(name)="row">
        {{ row.item.shortViews.name }}
      </template>

      <!-- Type -->
      <template #cell(type)="row">
        {{ row.item.shortViews.type }}
      </template>

      <!-- Logo -->
      <template #cell(logo)="row">
        <b-img
          :src="row.item.shortViews.logo"
          height="50"
          class="mr-1"
        />
      </template>

      <!-- Legal Name -->
      <template #cell(legal_name)="row">
        {{ row.item.shortViews.legal_name }}
      </template>

      <!-- SSN -->
      <template #cell(ssn)="row">
        {{ row.item.shortViews.ssn }}
      </template>

      <!-- EIN -->
      <template #cell(ein)="row">
        {{ row.item.shortViews.ein }}
      </template>

      <!-- Email -->
      <template #cell(email)="row">
        {{ row.item.shortViews.email }}
      </template>

      <!-- Phone -->
      <template #cell(phone)="row">
        {{ row.item.shortViews.phone }}
      </template>

      <!-- Fax -->
      <template #cell(fax)="row">
        {{ row.item.shortViews.fax }}
      </template>

      <!-- Address -->
      <template #cell(address)="row">
        {{ row.item.shortViews.address }}
      </template>

      <!-- action -->
      <template #cell(action)="row">
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <b-button
              variant="success"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item
            @click="editCompany(row.item.shortViews)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-1"
            />Edit
          </b-dropdown-item>
          <b-dropdown-item
            @click="deleteCompany(row.item.shortViews)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="mr-1"
            />Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import CompanyTableDetailView from './CompanyTableDetailView.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BImg,
    BFormCheckbox,
    CompanyTableDetailView,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [
          {
            id: '',
            name: '',
            type: '',
            logo: '',
            legal_name: '',
            ssn: '',
            ein: '',
            email: '',
            phone: '',
            fax: '',
            address: '',
            maling_address: '',
            scac_code: '',
            dba_name: '',
            usdot: '',
            operating_authority: '',
            insurance_expire_date: '',
          },
        ]
      },
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        'show_details',
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'logo', label: 'Logo', sortable: true },
        { key: 'legal_name', label: 'Legal Name', sortable: true },
        { key: 'ssn', label: 'SSN', sortable: true },
        { key: 'ein', label: 'EIN', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'fax', label: 'Fax', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    deleteCompany(item) {
      console.log(item)
    },

    editCompany(item) {
      this.$router.push({ name: 'edit-company', params: { id: item.id } })
    },
  },
}
</script>
